import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const Button = makeShortcode("Button");
const InfoBlock = makeShortcode("InfoBlock");
const Tout = makeShortcode("Tout");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box sx={{
      textAlign: "center"
    }} mdxType="Box">
      <h1>{`Contribute`}</h1>
      <p><img parentName="p" {...{
          "src": "/images/illus_work.png",
          "alt": "Work with us"
        }}></img></p>
    </Box>
    <h2>{`How you Play a Part is up to You!`}</h2>
    <p>{`Want to work in crypto? This is your opportunity. There are lots of ways to contribute to MakerDAO community development. `}</p>
    <p>{`Contribute to existing bounties, help us create and edit content, translate, host a meetup or hackathon, take notes on governance and risk meetings, and more.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '12px',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="liquidation" mdxType="Icon" />
          <h4>{`Bounties`}</h4>
          <p>{`See what tasks we need help with.`}</p>
          <Button to="/contribute/bounties/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Bounties</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="clipboard" mdxType="Icon" />
          <h4>{`Content`}</h4>
          <p>{`Write, edit, design, and code educational documents and resources.`}</p>
          <Button to="/contribute/content/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Content</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="globe" mdxType="Icon" />
          <h4>{`Translations`}</h4>
          <p>{`Translate our content into other languages.`}</p>
          <Button to="/contribute/translations/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Translations</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="people" mdxType="Icon" />
          <h4>{`Meetups`}</h4>
          <p>{`Host a meetup to educate and promote Maker and Dai.`}</p>
          <Button to="/contribute/meetups/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Meetups</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
 <Icon size={"40px"} name="laptop" mdxType="Icon" />
          <h4>{`Hackathons`}</h4>
          <p>{`Make something awesome with Maker or Dai.`}</p>
          <Button to="/contribute/hackathons/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Hackathons</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="forum" mdxType="Icon" />
          <h4>{`Governance & Risk`}</h4>
          <p>{`Take notes at our weekly governance and risk meetings.`}</p>
          <Button to="/contribute/governance_and_risk_meetings/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">G&R Meetings</Button>
        </Flex>
      </Chocolate>
    </Box>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <h2>{`Hire Yourself`}</h2>
        <p>{`If you want to help and have any of the skills listed above – you just passed the interview! `}</p>
        <p>{`Let us know what you want to work on and start earning Dai!`}</p>
      </Box>
    </InfoBlock>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h4>{`Say "Hi!"`}</h4>
        <p>{`The best place to start is in our chat. Say hello and get to know us. `}<Link to="https://chat.makerdao.com/channel/community-development" mdxType="Link">{`Community Chat`}</Link></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Got an Idea?`}</h4>
        <p>{`We welcome ideas for improving our community resources. Submit a `}<Link to="/funding/community-projects/" mdxType="Link">{`proposal`}</Link>{` and we’ll help you get started.`}</p>
      </Box>
    </Tout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      